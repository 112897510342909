import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import Brand from "../assets/media/brand.svg";

function Clients() {
  const [shots, setShots] = useState([]);

  useEffect(() => {
    const fetchShots = async () => {
      try {
        const response = await axios.get('https://api.dribbble.com/v2/user/shots?access_token=047ae13924e9bd9951bca1a689194a418a4cae0841028572aaf826c506cb7fee');
        setShots(response.data);
      } catch (error) {
        console.error('Error fetching shots:', error);
      }
    };

    fetchShots();
  }, []);

  return (
    <Container>
      <div className="who">
<div className="rl-child">
            <h4>~Our Portfolio</h4>
           </div>
        <div className="rl-child">
         
        </div>
        </div>
      <div className="shots-container">
        {shots.map(shot => (
        
          <div key={shot.id} className="shot">
              <a href={shot.html_url} target="_blank">
            <img src={shot.images.normal} alt={shot.title} />
            <p>{shot.title}</p>
            <div className="tags">
            {shot.tags.map(tag => (
            
          <span key={tag}>#{tag}</span>
        
        ))}
          </div>
            </a>
          </div>
        ))}
      </div>
    </Container>
  );
}

export default Clients;
