import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Slider from "../../components/Slider";
import Team from "../../components/Team";
function About() {

  return (
    <Container>
         
          <Team />
     
    </Container>
  );
}

export default About;
