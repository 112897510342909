import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { IoLogoFigma } from "react-icons/io5";
import { SiAdobexd } from "react-icons/si";
import BreadcrumbComponent from './BreadcrumbComponent';
import { CgFlagAlt } from "react-icons/cg";
import { ProgressBar } from 'react-bootstrap';
import { GoLinkExternal } from "react-icons/go";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {message } from 'antd';
import Steps from './Steps';
import moment from 'moment';
const ClientDetails = () => {
  const { name } = useParams();
  const [project, setProject] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const completedPercentage = (project.completedTasks / project.totalTasks) * 100 || null;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects/${name}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setProject(response.data.data);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  };

  const handleEditModalShow = () => {
    if (project.projectStatus === 'draft') {
      setShowEditModal(true);
      setEditedProject({ ...project });
    } else {
      message.error('Cannot edit project. Project status must be "Approved"');
    }
  };

  const handleEditModalClose = () => setShowEditModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProject({ ...editedProject, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects/${name}`, editedProject, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      message.success('Project updated successfully');
      setProject(editedProject); // Update local state with edited project data
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating project:', error);
      message.error('Failed to update project');
    }
  };

  

  return (
    <Container className='project-detail'>
    
      <Row>
        <Col>
          <div className='directory'>
            <BreadcrumbComponent projectName={project.description} />
          </div>
        
          <h1 id="head2">{project.name}</h1>
          <Steps />
        
          <br />
          <Button variant="primary" onClick={handleEditModalShow}>Edit Client Details</Button>
     
        </Col>
      </Row>


      <Modal show={showEditModal} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Project Name</Form.Label>
              <Form.Control type="text" name="name" value={editedProject.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Project Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={editedProject.description} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="priority">
              <Form.Label>Priority</Form.Label>
              <Form.Control as="select" name="priority" value={editedProject.priority} onChange={handleChange}>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="budget">
              <Form.Label>Budget</Form.Label>
              <Form.Control type="text" name="budget" value={editedProject.budget} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="deadline">
              <Form.Label>Deadline</Form.Label>
              <Form.Control type="date" name="deadline" value={editedProject.deadline} onChange={handleChange} />
            </Form.Group>
            {/* Additional fields can be added similarly */}
            <Button variant="primary" type="submit">Save Changes</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ClientDetails;
