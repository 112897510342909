import React, { useState } from 'react';

const Filters = () => {
  const [activeFilter, setActiveFilter] = useState('Ongoing');

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    console.log(`Selected filter: ${filter}`);
  };

  return (
    <nav className="filters">
      <ul>
        <li
          id={activeFilter === 'Ongoing' ? 'activee' : ''}
          onClick={() => handleFilterClick('Ongoing')}
        >
          Ongoing
        </li>
        <li
          id={activeFilter === 'Closed' ? 'activee' : ''}
          onClick={() => handleFilterClick('Closed')}
        >
          Closed
        </li>
        <li
          id={activeFilter === 'Draft' ? 'activee' : ''}
          onClick={() => handleFilterClick('Draft')}
        >
          Draft
        </li>
      </ul>
    </nav>
  );
};

export default Filters;
