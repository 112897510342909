import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FaChartLine, FaProjectDiagram, FaUsers, FaMoneyCheckAlt, FaServer, FaWallet, FaReceipt, FaExchangeAlt } from "react-icons/fa";

function Reports() {
  return (
    <Container>
        <div className="rl-child reports">
            <h4>~Reports</h4>
        </div>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <FaChartLine size={50} className="mr-3" />
                <div>
                  <h5>Congratulations!</h5>
                  <p>This month your profit is up!</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaProjectDiagram size={30} className="mr-3" />
              <h6>Active Projects</h6>
               <p>100</p>
              
             
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaUsers size={30} className="mr-3" />
              <h6>Total Team Members</h6>
              <p>Total team members: 25</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaMoneyCheckAlt size={30} className="mr-3" />
              <h6>Total Salary Paid</h6>
              <p>Total salary paid: $50,000</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaServer size={30} className="mr-3" />
              <h6>Server Money Paid</h6>
              <p>Server money paid: $5,000</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaWallet size={30} className="mr-3" />
              <h6>Other Subscriptions</h6>
              <p>Other subscriptions: $2,000</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaReceipt size={30} className="mr-3" />
              <h6>Total Expenses</h6>
              <p>Total expenses: $10,000</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4} className="mb-4">
          <Card>
            <Card.Body>
              <FaExchangeAlt size={30} className="mr-3" />
              <h6>Total Transactions</h6>
              <p>Total transactions: 150</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Reports;
