import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import Brand from "../assets/media/icon.svg";

function ReferComp() {
  const [shots, setShots] = useState([]);
  const [referralLink, setReferralLink] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchShots = async () => {
      try {
        const response = await axios.get('https://api.dribbble.com/v2/user/shots?access_token=047ae13924e9bd9951bca1a689194a418a4cae0841028572aaf826c506cb7fee');
        setShots(response.data);
      } catch (error) {
        console.error('Error fetching shots:', error);
      }
    };

    fetchShots();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // reset after 2 seconds
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const name = e.target.name.value;
    setReferralLink(`https://rapydlaunch.com/contact-us?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}`);
  };

  return (
    <Container>
      <div className="who">
        <div className="rl-child">
          <h4>~Refer & Earn</h4>
          <img src={Brand} id="small" alt="Brand Icon" />
        </div>
      
      </div>
      <div className="rl-child referral-form">
          <form onSubmit={handleFormSubmit} className="form">
            
              <label htmlFor="name">Your Name:</label>
              <input type="text" id="name" name="name" required />
        
          
              <label htmlFor="email">Your Email:</label>
              <input type="email" id="email" name="email" required />
 
            <button type="submit">Generate Referral Link</button>
          </form>
          {referralLink && (
            <div className="copy-re">
              <input type="text" value={referralLink} readOnly />
              <button onClick={handleCopy}>{copied ? "Copied!" : "Copy Link"}</button>
            </div>
          )}
        </div>

      <div className="meet-team" id="community">
        <h2>How It Works? <span id="notePri">Terms & Condition Included!</span></h2>
        <div className="testino">
          <ol className="why">
            <li><span>Step 01</span> Refer a friend and both of you can enjoy exclusive benefits. Here's how it works:</li>
            <li><span>Step 02</span> Your Friend Joins: When your friend starts a project with us, you both win! Your friend receives an amazing 5% discount on their project.</li>
            <li><span>Step 03</span> Your Reward: As a token of our appreciation, you also receive a 5% discount on your next project for every successful referral.</li>
            <li id="final"><span>Step 04</span> Double the Joy: The more friends you refer, the more you save (Up to 20%). Imagine the possibilities when your entire network benefits!</li>
          </ol>
        </div>
      </div>
    </Container>
  );
}

export default ReferComp;
