import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Third from "../assets/media/relations.png";
import Leader from "../assets/media/leader.png";
import Freelace from "../assets/media/freelance-work.png";

function Features() {

  return (
    <Container>


      <div className="meet-team">
    <h2>DO YOU WANT TO SCALE YOUR BUSINESS? </h2>
    <div className="choose-box">
     
        <div className="box">
          <img src={Third}/>
          <h1>I have Third-Party Projects!</h1>
          <p>(Refer and Earn?)</p>
          <a href="/contact-us"  target="_blank">Connect</a>
        </div>
        <div className="box">
        <img src={Leader}/>
          <h1>My own business</h1>
          <p>(For development/maintenance?)</p>
          <a href="/contact-us" target="_blank">Connect</a>
        </div>
        <div className="box">
        <img src={Freelace}/>
        <h1>Join Marketplace</h1>
          <p>(List your idea)</p>
          <a href="https://tally.so/r/mOP80g" target="_blank">Publish</a>
        </div>
    </div>
</div>







    </Container>
  );
}

export default Features;
