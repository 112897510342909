import React from 'react';
import { Table, Dropdown } from 'react-bootstrap';

const BillingPage = ({ clients }) => {
  const domain =  process.env.REACT_APP_DOMAIN_BILL;

  return (
    <Table striped bordered hover className='clients-table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>Contact Person</th>
          <th>Organisation Name	</th>
          <th>Status</th>
         
          <th>Amount</th>
          <th>Manage</th>
        </tr>
      </thead>
      <tbody>
        {clients.map((client,num) => (
          <tr key={client._id}>
            <td>{num+1}</td>
            <td>{client.customName}</td>
            <td>{client.orgName}</td>
          
            <td>{client.invoiceStatus}</td>
            <td>₹{client.totalINR}</td>
          
            <td className='action-btn'>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={`${domain}/invoice/${client._id}/view`} target="_blank">View Bill</Dropdown.Item>
               
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BillingPage;
