import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { IoLogoFigma } from "react-icons/io5";
import { SiAdobexd } from "react-icons/si";
import BreadcrumbComponent from './BreadcrumbComponent';
import { CgFlagAlt } from "react-icons/cg";
import { ProgressBar } from 'react-bootstrap';
import { GoLinkExternal } from "react-icons/go";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {message } from 'antd';
import moment from 'moment';
const ProjectDetail = () => {
  const { name } = useParams();
  const [project, setProject] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const completedPercentage = (project.completedTasks / project.totalTasks) * 100 || null;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects/${name}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setProject(response.data.data);
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  };

  const handleEditModalShow = () => {
    if (project.projectStatus === 'draft') {
      setShowEditModal(true);
      setEditedProject({ ...project });
    } else {
      message.error('Cannot edit project. Project status must be "Approved"');
    }
  };

  const handleEditModalClose = () => setShowEditModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProject({ ...editedProject, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects/${name}`, editedProject, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      message.success('Project updated successfully');
      setProject(editedProject); // Update local state with edited project data
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating project:', error);
      message.error('Failed to update project');
    }
  };

  

  return (
    <Container className='project-detail'>
    
      <Row>
        <Col>
          <div className='directory'>
            <BreadcrumbComponent projectName={project.description} />
           
          </div>
        
          <h1 id="head2">{project.name}</h1>
    
          <div className='rl-row'>
            <div className='timeline'>
            {project.priority && <p><strong>Priority:</strong> <span id={project.priority}><CgFlagAlt />{project.priority}</span></p>}
            {project.owner && <p><strong>Project Owner:</strong> {project.owner} {` `} | {` `} <a href=""> {project.phone}</a></p>}
            {project.projectManager && <p><strong>Project Manager:</strong> {project.projectManager}</p>}
            {project.developer &&  <p><strong>Developer:</strong> <span id={`assignee`}>{project.developer}</span></p>}
            {project.deadline && <p><strong>Deadline:</strong> {moment(project.deadline).format('DD MMMM YYYY')}</p>}
            {project.totalTasks != 0 &&  <p><strong>Total Tasks:</strong> {project.totalTasks}</p>}
            {project.totalDevelopers != 0 &&   <p><strong>Total Developers:</strong> {project.totalDevelopers}</p>}
            {completedPercentage &&  <p><strong>Completed Tasks:</strong> <ProgressBar now={completedPercentage} label={`${completedPercentage}%`} /></p>}
            {project.totalMeetings != 0 && <p><strong>Total Meetings:</strong> {project.totalMeetings}</p>}
            {project.totalPhase != 0 && <p><strong>Phases:</strong> <span id="">Total Phases {project.totalPhase}  </span> <span id="paid">{project.completedPhase} Completed</span> <span id="due"> Next Meeting : {project.nextPhaseMeeting}</span></p>}
            {project.nextPaymentDate && <p><strong>Next Payment Date:</strong> <span id="assignee">{project.nextPaymentDate}</span></p>}
            {project.designFileLink &&<p><strong>Design Link</strong> <a href={project.designFileLink} target="_blank" rel="noopener noreferrer"><IoLogoFigma /></a></p>}
            {project.documents >=0 &&<p><strong>Documents Link</strong> <a href={project.documents} target="_blank" rel="noopener noreferrer">Link</a></p>}
           
              <div className='timeline-other'>
              {project.tag && <p><strong>Tags:</strong> <span id="tags">{project.tag}</span></p>}
              {project.server && <p><strong>Server Status:</strong> <span id="server">{project.server}</span></p>}
              {project.budget &&<p><strong>Budget:</strong><span id="total">₹{project.budget}</span></p>}
              </div>
            </div>
            <div className='application-preview'>
              <div className='rotate'>
                <img src='https://mobbin.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fproduct-cover-ios.96275797.png&w=3840&q=75' />
              </div>
            </div>
          </div>
          <br />
          <Button variant="primary" onClick={handleEditModalShow}>Edit Project</Button>
     
        </Col>
      </Row>


      <Modal show={showEditModal} onHide={handleEditModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Project Name</Form.Label>
              <Form.Control type="text" name="name" value={editedProject.name} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Project Description</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={editedProject.description} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="priority">
              <Form.Label>Priority</Form.Label>
              <Form.Control as="select" name="priority" value={editedProject.priority} onChange={handleChange}>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="budget">
              <Form.Label>Budget</Form.Label>
              <Form.Control type="text" name="budget" value={editedProject.budget} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="deadline">
              <Form.Label>Deadline</Form.Label>
              <Form.Control type="date" name="deadline" value={editedProject.deadline} onChange={handleChange} />
            </Form.Group>
            {/* Additional fields can be added similarly */}
            <Button variant="primary" type="submit">Save Changes</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ProjectDetail;
