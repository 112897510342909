import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';
const ClientOnboardForm = ({ show, onHide, onAddClient }) => {
  const [clientData, setClientData] = useState({
    nameOfContact: '',
    phone: '',
    email: '',
    address: '',
    status: 'Active',
    onboardedDate: new Date().toLocaleDateString(),
    companyName:'',
    companyNumber:'',
    orgName:''
  });
  const [messageApi, contextHolder] = message.useMessage();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'http://localhost:1900/auth/v1/onboard-client',
        clientData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`, 
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data.status) {
        const key = 'updatable';
        messageApi.loading({ content: 'Onboarding...', key });
  
        setTimeout(() => {
          messageApi.success({ content: 'Client onboarded successfully!', key, duration: 2 });
          setTimeout(() => {
            onHide();
            window.location.reload();
          }, 800);
        }, 2000);
  
        onAddClient(clientData); 
      
      } else {
        messageApi.error('Failed to onboard client. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      messageApi.error('Failed to onboard client. Please try again.');
    }
  };
  


  return (
    <Modal show={show} onHide={onHide}>
       {contextHolder}
      <Modal.Header closeButton>
        <Modal.Title>Onboard New Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="nameOfContact"
              value={clientData.nameOfContact}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="orgName">
            <Form.Label>Organisation Name</Form.Label>
            <Form.Control
              type="text"
              name="orgName"
              value={clientData.orgName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="contact">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="number"
              name="phone"
              value={clientData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={clientData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

    

          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="address"
              value={clientData.address}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="contact">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              value={clientData.companyName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="contact">
            <Form.Label>Company Website</Form.Label>
            <Form.Control
              type="text"
              name="companyWebsite"
              value={clientData.companyWebsite}
              onChange={handleChange}
              required
            />
          </Form.Group>


          <Form.Group controlId="contact">
            <Form.Label>Company Registered Number</Form.Label>
            <Form.Control
              type="text"
              name="companyNumber"
              value={clientData.companyNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ClientOnboardForm;
