import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Slider from "../../components/Slider";
import Features from "../../components/Features";
import Who from "../../components/Who";
import Focus from "../../components/Focus";
import SliderMark from "../../components/marketplace/Slider";
function MarketPlace() {

  return (
    <Container>
        
        <SliderMark />
     
    </Container>
  );
}

export default MarketPlace;
