import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";

function Focus() {

  return (
    <Container>
   
         

     

      <div className="meet-team">
        <h2>We focus on!</h2>
        <div className="testino">
          <div className="services">
            <p>Web Developement</p>
          </div>
          
          <div className="services">
            <p>Wordpress Theme</p>
          </div>
          <div className="services">
            <p>Business Developement</p>
          </div>
          <div className="services">
            <p>Whatsapp Automation</p>
          </div>
          <div className="services">
            <p>Mobile Application</p>
          </div>
          <div className="services">
            <p>Business Automation</p>
          </div>
          <div className="services">
            <p>B2B Ecommerce</p>
          </div>
          <div className="services">
            <p>Shopify Developement</p>
          </div>
          <div className="services">
            <p>HR Interaction</p>
          </div>
          <div className="services">
            <p>Content Strategy</p>
          </div>
          <div className="services">
            <p>Product Strategy</p>
          </div>
          <div className="services">
            <p>Desktop App</p>
          </div>
          <div className="services">
            <p>Business Tools</p>
          </div>
          <div className="services">
            <p>Revamp Product</p>
          </div>
          <div className="services">
            <p>Automation QA</p>
          </div>

          <div className="services">
            <p>AI/ML Chatbots</p>
          </div>

          
          <div className="services">
            <p>ERP Tools</p>
          </div>

          <div className="services">
            <p>CRM Tools</p>
          </div>

          <div className="services">
            <p>Billing System</p>
          </div>

          <div className="services">
            <p>Maintenance & Support</p>
          </div>

          
         
        </div>
        
      </div>



      <div className="meet-team" id="communty">
        {/* <h2>Meet our rapid team!</h2> */}
        <div className="testino">
          {/* <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1695584577430.png"/>
            </div>
            <div className="test-details">
              <h2>Aman Sharma</h2>
              <p>Founder</p> 
            </div>
          </div> */}

          {/* <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1695584667668.png"/>
            </div>
            <div className="test-details">
              <h2>Abhishek Yadav</h2>
              <p>CFO & Product</p> 
            </div>
          </div> */}

          {/* <div className="testinomials">
            <div className="test-image">
              <img src="https://media.licdn.com/dms/image/D5603AQHaittMvPLI7g/profile-displayphoto-shrink_800_800/0/1685338862438?e=1708560000&v=beta&t=LsEfQqHG9AV2_FlaOymiRD5U6_Hz8PibVG2YAwtnCy8"/>
            </div>
            <div className="test-details">
              <h2>Sandeep Kumar</h2>
              <p>CTO/Product Manager</p> 
            </div>
          </div> */}

          {/* <div className="testinomials">
            <div className="test-image">
              <img src="https://pbs.twimg.com/profile_images/1266180964696199169/362QuY7O_400x400.jpg"/>
            </div>
            <div className="test-details">
              <h2>Pranav MS</h2>
              <p>Product Manger</p> 
            </div>
          </div> */}

          {/* <div className="testinomials">
            <div className="test-image">
              <img src={Nyasha}/>
            </div>
            <div className="test-details">
              <h2>Nyasha Gupta</h2>
              <p>Product Growth</p> 
            </div>
          </div> */}



          {/* <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1690284864386.jpeg"/>
            </div>
            <div className="test-details">
              <h2>Aayush Shrivastav</h2>
              <p>Developer</p> 
            </div>
          </div>

          */}
          {/* <div className="testinomials">
            <div className="test-image">
              <img src={Mohit}/>
            </div>
            <div className="test-details">
              <h2>Mohit Kumar Saroj</h2>
              <p>Digital Strategy</p> 
            </div>
          </div> */}

         

         
          
         
        </div>
        
      </div>



        <div className="meet-team" id="communty">
        <h2 id="center">Let us find right fit for you?</h2>
         <div className="connect meet">
           <a href="https://calendly.com/rapydlaunch/30min" target="_blank">Let's Talk</a>
         </div>
        </div>


        <div className="meet-team" id="communty">
        <h2>How 45 days timeline works? <span id="notePri">Terms & Condition Included!</span></h2>
        <div className="testino">
          <ol className="why how">
          <li><span>Days 01 to 03</span> Requirement and planning.</li>
          <li><span>Days 04 to 34</span> Design & Development. </li>
          <li><span>Days 34 to 39</span> Testing and quality assurance. </li>
          <li><span>Days 40 to 43</span> Your feedback and revisions.</li>
          <li id="final"><span>Days 44 to 45</span> Final product in the market.</li>
          
         </ol>
        </div>
        </div>

        <div className="meet-team" id="communty">
        <h2>Why RapydLaunch?</h2>
        <div className="testino">
          <ol className="why">
           <li>Speedy Development: Our primary goal is to deliver a fully functional product within an unprecedented timeframe of 45 days, ensuring a rapid go-to-market strategy.</li>
           <li>Efficient Resource Utilization: By providing a dedicated team, we ensure that your project receives focused attention, maximizing productivity and minimizing delays.</li>
           <li>Reduced Time-to-Market: Accelerating the development process translates to quicker market entry, allowing you to stay ahead of competitors and capture early market share.</li>
           <li>No Need for Tech Hiring: Save the hassle of recruiting and managing an in-house tech team. We offer a dedicated team of professionals, reducing the burden on your HR department and allowing you to focus on core business activities.</li> 
          <li>Exclusive Referral Benefits: Enjoy an additional incentive with a 5% discount on our services for every successful referral, fostering a collaborative community while providing cost savings for you and your network.</li> 
          
         </ol>
        </div>
        </div>
    




    </Container>
  );
}

export default Focus;
