import React, { useEffect, useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import { FaStopwatch } from "react-icons/fa";
import { TiPlusOutline } from "react-icons/ti";
import Filters from './Filter';
import ProjectsTable from '../../../components/Table/ProjectTable';
import { IoGridOutline } from "react-icons/io5";
import { MdFilterList } from "react-icons/md";
import axios from 'axios';
import NewProject from './NewProject';
import Steps from './Steps';
import { message, Modal as AntModal } from 'antd';


const Projects = () => {
  const [showOnboardForm, setShowOnboardForm] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [viewType, setViewType] = useState('grid'); 
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const userToken = localStorage.getItem('userToken');
  const [messageApi, contextHolder] = message.useMessage();

  const toggleView = (view) => {
    setViewType(view);
  };

  const handleToggleForm = () => setShowOnboardForm(!showOnboardForm);

  const handleAddProject = (newProject) => {
    const newProjectWithId = { ...newProject, id: projects.length + 1 };
    setProjects([...projects, newProjectWithId]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects`, {
          headers: {
            'Authorization': `Bearer ${userToken}`
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (projectId) => {
    // Close the delete confirmation modal
    setDeleteConfirmation(null);

    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects/${projectId}`,
      headers: { 
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {
        messageApi.success('Project deleted successfully');
        setProjects(projects.filter(project => project.projectMongo !== projectId));
      })
      .catch((error) => {
        console.error('Error deleting project:', error);
        messageApi.error('Failed to delete project');
      });
  };

  const showDeleteConfirmation = (projectId) => {
    setDeleteConfirmation(
      <AntModal
        title="Confirm Delete"
        visible={true}
        onCancel={() => setDeleteConfirmation(null)}
        footer={[
          <Button key="cancel" onClick={() => setDeleteConfirmation(null)}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" danger onClick={() => handleDelete(projectId)}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this project?</p>
      </AntModal>
    );
  };

  return (
    <Container className='container'>
      <h1 id="head">Projects Ongoing ({projects.length})</h1>
    
      {projects.length > 0 &&
        <div className='row-rl'>
          <nav className="filters">
            <ul>
              <li
                id={viewType === 'grid' ? 'activee' : ''}
                onClick={() => toggleView('grid')}
              >
                <IoGridOutline /> Grid
              </li>
              <li
                id={viewType === 'list' ? 'activee' : ''}
                onClick={() => toggleView('list')}
              >
                <MdFilterList /> List View
              </li>
            </ul>
          </nav>
        </div>
      }

      <div className={viewType === 'grid' ? 'allprojects grid-view' : 'allprojects list-view'}>
        {viewType === 'grid' &&
          <>
            {projects.map(project => (
              <div key={project.id} className={`projects-div pr-${project.progress} ${project.id >= 2 ? 'eighty' : ''}`}>
                <a href={`projects/${project.projectMongo}`}>
                  <div className="folder-icon">
                    <div className="folder-top"> {project.progress}%</div>
                    <div className="folder-bottom"></div>
                  </div>
                  <span className='project-type'>{project.type}</span>
                  <h2 className='project-name'>{project.name}</h2>
                  <p className='project-description'>{project.description}</p>

                  <div className='two-sec'>
                    <p className='project-deadline'>
                      <span><FaStopwatch /> {project.deadline}</span>
                    </p>
                    {/* <p className='project-developer'>
                      <span id="dev">{project.developer}</span>
                    </p> */}
                  </div>

                </a>
                {project.canDelete &&
                  <button id="red" onClick={() => showDeleteConfirmation(project.projectMongo)}>Delete</button>
                }
              </div>
            ))}
            {projects.length >= 0 &&
              <div className='projects-div start-new' onClick={handleToggleForm}>
                <TiPlusOutline />
                Start New Project or <a href="calendar/">schedule call</a>
              </div>
            }
          </>
        }
        {viewType === 'list' &&
          <ProjectsTable projects={projects} />
        }
      </div>

      <NewProject
        show={showOnboardForm}
        onHide={handleToggleForm}
        clients={clients}
        onAddProject={handleAddProject}
      />
      
      {contextHolder}
      {deleteConfirmation}
    </Container>
  );
};

export default Projects;
