// ClientComp.js

import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import ClientOnboardForm  from './ClientOnboardForm'; // Import the new component
import { FaPhone } from "react-icons/fa";
import { TiPlusOutline } from "react-icons/ti";
import ClientTable from '../../../components/Table/ClientPage';
import axios from 'axios';
const ClientComp = () => {
  const [showOnboardForm, setShowOnboardForm] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(()=>{
    let data = '';
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/v1/all-users`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      setClients(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
    
      })
    

  const handleAddClient = (newClient) => {
   
    const newClientWithId = { ...newClient, id: clients.length + 1 };
    setClients([...clients, newClientWithId]);
  };

  const handleToggleForm = () => setShowOnboardForm(!showOnboardForm);

  const handleViewClient = (id) => {
    console.log('View Client', id);
  };

  const handleViewProjects = (id) => {
    console.log('View Projects', id);
  };

  const handleViewPayments = (id) => {
    console.log('View Payments', id);
  };

  const handleSendPaymentReminder = (id) => {
    console.log('Send Payment Reminder', id);
  };

  return (
    <Container className='container'>
        <div className='row-rl'>
        <h1 id="head">Clients ({clients.length})</h1>
      
      <Button variant="primary" onClick={handleToggleForm}>
        <TiPlusOutline /> Onboard Client
      </Button>
        </div>
     

      <ClientOnboardForm
        show={showOnboardForm}
        onHide={handleToggleForm}
        onAddClient={handleAddClient}
      />
      
      <ClientTable 
        clients={clients} 
        onViewClient={handleViewClient}
        onViewProjects={handleViewProjects}
        onViewPayments={handleViewPayments}
        onSendPaymentReminder={handleSendPaymentReminder}
      />
    </Container>
  );
};

export default ClientComp;
