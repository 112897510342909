import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Slider from "../../components/Slider";
import Features from "../../components/Features";
import Who from "../../components/Who";
import Focus from "../../components/Focus";
function Homescreen() {

  return (
    <Container>
          <Slider />
          <Features />
          <Focus />
          <Who />
     
    </Container>
  );
}

export default Homescreen;
