import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Slider from "../../components/Slider";
import Features from "../../components/Features";
import Who from "../../components/Who";
import Clients from "../../components/Clients";
import Focus from "../../components/Focus";
function Portfolio() {

  return (
    <Container>
       
          <Clients />
     
    </Container>
  );
}

export default Portfolio;
