import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import ReferComp from "../../components/Referals";
function Referrals() {

  return (
    <Container>
       
          <ReferComp />
     
    </Container>
  );
}

export default Referrals;
