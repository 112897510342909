// src/components/Breadcrumb.js
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const BreadcrumbComponent = ({ projectName }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item href="/">Dashboard / Projects</Breadcrumb.Item>
      <Breadcrumb.Item active>EasyHaiBro</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
