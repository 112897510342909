import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FaPhone } from "react-icons/fa";
import { TiPlusOutline } from "react-icons/ti";
import ClientTable from '../../../components/Table/ClientPage';
import BillingPage from '../../../components/Table/BillingPage';
import axios from 'axios';
const ClientComp = () => {

  const [clients, setClients] = useState([]);

  useEffect(()=>{
    let data = '';
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/v1/myinvoices`,
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      setClients(response.data.invoices);
    })
    .catch((error) => {
      console.log(error);
    });
    
      })
    

  useEffect(() => {

  }, []);

  const handleViewClient = (id) => {
    console.log('View Client', id);
  };

  const handleViewProjects = (id) => {
    console.log('View Projects', id);
  };

  const handleViewPayments = (id) => {
    console.log('View Payments', id);
  };

  const handleSendPaymentReminder = (id) => {
    console.log('Send Payment Reminder', id);
  };

  return (
    <Container className='container'>
      <h1 id="head">Billings ({clients.length})</h1>
      
      <BillingPage 
        clients={clients} 
        onViewClient={handleViewClient}
        onViewProjects={handleViewProjects}
        onViewPayments={handleViewPayments}
        onSendPaymentReminder={handleSendPaymentReminder}
      />
      
     
    </Container>
  );
};

export default ClientComp;
