import React, { useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios'; 

const SlackMessagingComponent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [slackResponse, setSlackResponse] = useState('');

  const sendSlackMessage = async () => {
    const slackWebhookUrl = 'YOUR_SLACK_WEBHOOK_URL';
    const payload = {
      text: `New message from ${name} (${email}): ${message}`
    };

    try {
      // Send message to Slack
      const response = await axios.post(slackWebhookUrl, payload);
      console.log('Message sent to Slack:', response.data);
      setSlackResponse('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message to Slack:', error);
      setSlackResponse('Failed to send message. Please try again later.');
    }
  };

  return (
    <Container>
      <h1 id="head">Send Message via Slack</h1>
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Enter your message" value={message} onChange={(e) => setMessage(e.target.value)} />
        </Form.Group>

        <Button variant="primary" onClick={sendSlackMessage}>Send Message</Button>
      </Form>
      {slackResponse && <p>{slackResponse}</p>}
    </Container>
  );
};

export default SlackMessagingComponent;
