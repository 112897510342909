import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Navbar, Dropdown } from "react-bootstrap";
import Logo from "../../assets/media/logo512svg.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
function Header() {
  const navigate = useNavigate();
 const [users,setUsers] = useState('');
 const loggedIn =  localStorage.getItem('loggedIn');

 useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/user`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      setUsers(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  fetchData(); 

 
}, []); 

  const handleLogout = ()=>{
    localStorage.clear();
    window.location.reload();
  }

  return (
    <>
      <Navbar bg="" expand="lg">
        <Container id="navTop">
          <Navbar.Brand href="">
            <a href="/">
              <div className="multigradLogo">
                <img src={Logo} alt={`Multigrad App`} />
                <p>/hello@rapydlaunch.com</p>
              </div>
              </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
          {!loggedIn &&
              <>
             
                <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/about-us">About</Nav.Link>
                  <Nav.Link href="/dashboard/calendar/">Meeting</Nav.Link>
                  {/* <Nav.Link href="/marketplace">Marketplace  </Nav.Link> */}
                  <Nav.Link href="/portfolio">Work <span id="premouim">80+ Projects</span></Nav.Link>
                </Nav>
                 
                  <a id="signin" href="contact-us">
                  + Contact Us</a>
              </>   
}

{loggedIn &&
    <>
        <Nav className="me-auto">
        {/* <Nav.Link href="/dashboard/reports">Dashboard</Nav.Link> */}
        <Nav.Link href="/dashboard/clients/">Clients</Nav.Link>
        <Nav.Link href="/dashboard/projects">Projects</Nav.Link>
        <Nav.Link href="/dashboard/billings/">Billing</Nav.Link>
        <Nav.Link href="/dashboard/calendar/">Calendar <span id="premouimblue">Calendly</span></Nav.Link>
            
        </Nav>

        <Dropdown align="end">
            <Dropdown.Toggle id="profile" variant="link">
                <img src="https://api.multigrad.in/open/media/?file=1718555252521.png" alt="Aman Sharma" />
                <p>{users.fullName}</p>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* <Dropdown.Item href="/profile">Profile</Dropdown.Item> */}
                {/* <Dropdown.Item href="/pricing">Pricing</Dropdown.Item> */}
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>
}

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
