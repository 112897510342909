// src/App.js
import React, { useState } from 'react';
import { Popover, Steps } from 'antd';
import { Container } from 'react-bootstrap';

const { Step } = Steps;

// JSON data for the steps
const stepsData = [
  { title: "Profile Completion", status: "finish"},
  { title: "Project Listed", status: "finish" },
  { description: "Schedule Meeting.",  status: "wait", url: "calendar/" },
  { title: "Proposal & Budget", status: "wait" },
  { title: "Requirement", status: "wait" },
  { title: "First Demo", status: "wait" },
  { title: "Second Demo", status: "wait" },
  { title: "Final Demo", status: "wait" },
  { title: "Project Delivered", status: "wait" },
  { title: "Review", status: "wait"},
];


const customDot = (dot, { status, index }) => (
  <Popover content={<span>Step {index + 1} status: {status}</span>}>
    {dot}
  </Popover>
);

const App = () => {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <Container>
      <Steps current={currentStep} progressDot={customDot}>
        {stepsData.map((step, index) => (
          <Step
            key={index}
            title={step.title}
            description={
              step.url ? (
                <a href={step.url} target="_blank" rel="noopener noreferrer">
                  {step.description}
                </a>
              ) : (
                step.description
              )
            }
            status={step.status}
          />
        ))}
      </Steps>
    </Container>
  );
};

export default App;
