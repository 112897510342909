import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Brand from "../../assets/media/brand.svg";
function Contact() {
  useEffect(() => {
    const d = document;
    const scriptSrc = "https://tally.so/widgets/embed.js";

    const loadTally = () => {
      if (typeof window.Tally !== "undefined") {
        window.Tally.loadEmbeds();
      } else {
        d.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframe) => {
          iframe.src = iframe.dataset.tallySrc;
        });
      }
    };

    if (typeof window.Tally !== "undefined") {
      loadTally();
    } else if (!d.querySelector(`script[src="${scriptSrc}"]`)) {
      const script = d.createElement("script");
      script.src = scriptSrc;
      script.onload = loadTally;
      script.onerror = loadTally;
      d.body.appendChild(script);
    }
  }, []);

  return (
    <Container>
        <div className="who">
<div className="rl-child">
            <h4>~Contact us</h4>
       
            </div>
       
        </div>
      <iframe
        data-tally-src="https://tally.so/embed/3E1bWN?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="80%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="RapydLaunch"
      ></iframe>
    </Container>
  );
}

export default Contact;
