import React, { useState, useEffect } from "react";
import axios from 'axios';
import Container from "react-bootstrap/Container";
import "./LoginComp.css";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
function LoginComp() {
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  useEffect(() => {
    let interval;
    if (timerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(interval);
            setTimerActive(false);
            return 60;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerActive]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSendOtp = () => {
    setOtpSent(true);
    setTimerActive(true);
  };

  const handleResendOtp = () => {
    setOtpSent(true);
    setTimerActive(true);
    setTimer(60);
  };

  const handleOtpInputChange = (event) => {
    setOtpInput(event.target.value);
  };

  const handleLogin = () => {
    const data = JSON.stringify({
      email: email,
      password: otpInput
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_BASE_URL}/auth/v1/login`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status === true) {
          const key = 'updatable';
          messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
          });
          setTimeout(() => {
            messageApi.open({
              key,
              type: 'success',
              content: 'Logged in successfully!',
              duration: 2,
            });
          }, 1000);
          localStorage.setItem('userToken', response.data.userToken);
          localStorage.setItem('loggedIn', true);
          navigate("/dashboard/projects");
        }
      })
      .catch((error) => {
        messageApi.open({
          type: 'error',
          content: 'Login failed!',
          duration: 2,
        });
      });
  };

  return (
    <Container>
      {contextHolder}
      <div className="login_page">
        <div className="login-form">
          <h1>Welcome back</h1>
          <input
            type="email"
            placeholder="Enter Email Address"
            value={email}
            onChange={handleEmailChange}
            disabled={otpSent} // Disable email input if OTP is sent
          />
          {!otpSent ? (
            <button
              disabled={!email}
              className={email ? "active-class" : "disabled-class"}
              onClick={handleSendOtp}
            >
              Next
            </button>
          ) : (
            <div>
              <input
                type="password"
                placeholder="Password"
                value={otpInput}
                onChange={handleOtpInputChange}
              />
              {!otpSent && (
                <button
                  className={timer !== 0 ? "resend-btn" : "resend-btn-disabled"}
                  disabled={timer !== 0}
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </button>
              )}
            </div>
          )}
          {otpSent && (
            <button
              className={(email && otpInput) ? "active-class" : "disabled-class"}
              disabled={otpInput === ""}
              onClick={handleLogin}
            >
              Login
            </button>
          )}

          <p id="privacy">By continuing, you agree to rapydlaunch <a href="">Terms of Service</a> and <a href="">Privacy Policy</a>.</p>
        </div>
      </div>
    </Container>
  );
}

export default LoginComp;
