import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Bill from "../../assets/media/invoice (1).png";
import CRM from "../../assets/media/crm.png";
import Social from "../../assets/media/social-media.png";
import { FcNext } from "react-icons/fc";

function Inventory() {

  return (
    <Container>
   
<div className="meet-team">
  <h2>All the software you need to run your business!</h2>
  <div className="testino inv">

    <a href="" target="_blank">
    <div className="services available">
      <img src={Bill} />
      <h1>Accounting Management</h1>
      <p>Manages finances, tracks expenses, generates financial reports, and handles invoicing.</p>
      <a href="">Try Now <FcNext /></a>
    </div>
    </a>
    <a href="" target="_blank">
    <div className="services available">
      <img src={CRM} />
      <h1>Customer relation Management</h1>
      <p>Manages customer data, tracks customer and interactions.</p>
      <a href="">Try Now <FcNext /></a>
    </div>
    </a>

    <a href="" target="_blank">
    <div className="services available">
      <img src={Social} />
      <h1>campaigns Management</h1>
      <p>Manages social media accounts, schedules posts, and analyzes marketing performance.</p>
      <a href="">Try Now <FcNext /></a>
    </div>
    </a>

    <a href="" target="_blank">
    <div className="services available">
      <img src={Social} />
      <h1>campaigns Management</h1>
      <p>Manages social media accounts, schedules posts, and analyzes marketing performance.</p>
      <a href="">Try Now <FcNext /></a>
    </div>
    </a>

    <a href="" target="_blank">
    <div className="services available">
      <img src={Social} />
      <h1>campaigns Management</h1>
      <p>Manages social media accounts, schedules posts, and analyzes marketing performance.</p>
      <a href="">Try Now <FcNext /></a>
    </div>
    </a>

    <a href="" target="_blank">
    <div className="services available">
      <img src={Social} />
      <h1>campaigns Management</h1>
      <p>Manages social media accounts, schedules posts, and analyzes marketing performance.</p>
      <a href="">Try Now <FcNext /></a>
    </div>
    </a>


  </div>
</div>



 



    </Container>
  );
}

export default Inventory;
