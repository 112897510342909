import React, { useState } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import moment from 'moment';

const ClientTable = ({ clients }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = clients.slice(indexOfFirstClient, indexOfLastClient);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = () => {
    // Implement delete functionality
  };

  return (
    <div>
      <Table striped bordered hover className='clients-table'>
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Projects</th>
            <th>Onboarded Date</th>
            <th>Account</th>
            <th>Paid Amount</th>
            <th>Due Amount</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {currentClients.map((client) => (
            <tr key={client.id}>
              <td>{client.clientId}</td>
              <td>{client.fullName}</td>
              <td>{client.email}</td>
              <td>{client.phone}</td>
              <td>Active</td>
              <td>{client.numberOfProjects}</td>
              <td>{moment(client.createdDate).format('D MMMM YYYY | hh:mm')}</td>
              <td>₹{client.totalBudget || '0'}</td>
              <td>{client.totalPaid}</td>
              <td>{client.totalDue}</td>
              <td className='action-btn'>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item href={`view/${client.clientIdMongo}`}>View Client</Dropdown.Item> */}
                    <Dropdown.Item href={`projects/${client.clientIdMongo}`}>View Projects</Dropdown.Item>
                    {/* <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item> */}
                   
                    {/* <Dropdown.Item href="#/view">View Payments</Dropdown.Item>
                    <Dropdown.Item href="#/payment-reminder">Payment Reminder</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {clients.length > clientsPerPage && (
        <nav>
          <ul className="pagination">
            {[...Array(Math.ceil(clients.length / clientsPerPage)).keys()].map((pageNumber) => (
              <li key={pageNumber + 1} className={`page-item ${currentPage === pageNumber + 1 ? 'active' : ''}`}>
                <button onClick={() => paginate(pageNumber + 1)} className="page-link">
                  {pageNumber + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default ClientTable;
