import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Product from "../assets/media/product.svg";
import FS from "../assets/media/fs.svg";
import VS from "../assets/media/versatile.svg";
import MM from "../assets/media/mm.svg";
import EHB from "../assets/media/ehv.svg";
import EE from "../assets/media/ee.svg";
import EB from "../assets/media/eb.svg";
import ImpactEco from "../assets/media/im.svg";
import Multigrad from "../assets/media/mult.svg";
import YelloMonkey from "../assets/media/yello.webp";
import Texcyle from "../assets/media/texcyle.jpeg";
function Slider() {

  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeft mobile">
          <img id="product" src={Product} />
          <h2>
          B2B Software Marketplace
          </h2>
          <div className="app">
            
           
          <div className="playstore down">
          <a href="contact-us"> <button>GET STARTED FOR FREE</button> </a>
          <a href="refer-and-earn"> <button id="light">Refer & Earn</button></a>
      </div>
     
           
          <p>TURN YOUR <span id="border">IDEA</span> INTO A <span id="border">Product</span>  IN JUST 45 Days.</p>

  
      
          </div>

          <div className="clientdiv">
            <h3>COMPANIES THAT TRUST US!</h3>
            <div className="cl">
            <div className="client-list">
              <a href="http://florosense.co/" target="_blank">
               <img src={FS} />
              </a>
             
            </div>
            <div className="client-list">
            <a href="https://versatile.club/" target="_blank">
              <img src={VS} />
            </a>
            </div>
            <div className="client-list">
            <a href="http://easyhaibro.com/" target="_blank">
              <img src={EHB} />
              </a>
            </div>
            <div className="client-list">
            <a href="http://dwall.world" target="_blank">
              <img src={MM} />
              </a>
            </div>
            <div className="client-list">
            <a href="https://bill.easyexport.in/" target="_blank">
              <img src={EB} />
              </a>
            </div>
            <div className="client-list">
            <a href="https://easyexport.in/" target="_blank">
              <img src={EE} />
              </a>
            </div>
            <div className="client-list">
            <a href="https://ie.money/" target="_blank">
              <img src={ImpactEco} />
              </a>
            </div>

            <div className="client-list">
            <a href="https://multigrad.in" target="_blank">
              <img src={Multigrad} />
              </a>
            </div>
            <div className="client-list">
            <a href="https://texcyle.co/" target="_blank">
              <img src={Texcyle} />
              </a>
            

           
            </div>

            <div className="client-list">
            <a href="https://yellomonkey.com/" target="_blank">
              <img src={YelloMonkey} />
              </a>
            </div>

           
           
           
          </div>
          </div>
     
     
        </div>

      
      </div>



    </Container>
  );
}

export default Slider;
