import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Projects from './Projects';
import Billing from './Billing';
import Settings from './Settings';
import { Container } from 'react-bootstrap';

const Dashboard = () => {
  return (
    <Container className='container'>
      <h1>Dashboard</h1>
      <nav className="menu-bar">
        <ul>
          <li><Link to="projects">Projects</Link></li>
          <li><Link to="billing">Billing</Link></li>
          <li><Link to="settings">Settings</Link></li>
        </ul>
      </nav>
    </Container>
  );
};

export default Dashboard;
