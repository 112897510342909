import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaStopwatch } from "react-icons/fa";
import { TiPlusOutline } from "react-icons/ti";
import Filters from './Filter';
import ProjectsTable from '../../../components/Table/ProjectTable';
import { IoGridOutline } from "react-icons/io5";
import { MdFilterList } from "react-icons/md";
import axios from 'axios';
import NewProject from './NewProject';
import { useParams } from 'react-router-dom';
import moment from 'moment';
const ClientProjects = () => {
  const [showOnboardForm, setShowOnboardForm] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [viewType, setViewType] = useState('grid'); 
  const {id} = useParams();

  const toggleView = (view) => {
    setViewType(view);
  };

  const handleToggleForm = () => setShowOnboardForm(!showOnboardForm);

  const handleAddProject = (newProject) => {
    const newProjectWithId = { ...newProject, id: projects.length + 1 };
    setProjects([...projects, newProjectWithId]);
  };
  useEffect(()=>{
   
let data = '';

let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects_clients/${id}`,
  headers: { 
    'Authorization': `Bearer ${localStorage.getItem('userToken')}`
  },
  data : data
};

axios.request(config)
.then((response) => {
  setProjects(response.data.data);
})
.catch((error) => {
  console.log(error);
});

  })


  return (
    <Container className='container'>
      <h1 id="head">Projects Ongoing ({projects.length})</h1>

      <div className='row-rl'>
      {/* <Filters /> */}

      <nav className="filters">
      <ul>
        <li
          id={viewType === 'grid' ? 'activee' : ''}
          onClick={() => toggleView('grid')}
        >
        <IoGridOutline />{` `} <span> Grid</span> 
        </li>
        <li
          id={viewType === 'list' ? 'activee' : ''}
          onClick={() => toggleView('list')}
        >
        <MdFilterList />  List View
        </li>
        
      </ul>
    </nav>
    </div>
      
  
    
      <div className={viewType === 'grid' ? 'allprojects grid-view' : 'allprojects list-view'}>
      {viewType == 'grid' &&
        <>
         {projects.map(project => (
          <>  
          <div key={project._id} className={`projects-div pr-${project.progress} ${project.id >= 2 ? 'eighty' : ''}`}>
            <a href={`/dashboard/projects/${project._id}`}>
            <div className="folder-icon">
              <div className="folder-top"> {project.progress || 0}%</div>
              <div className="folder-bottom"></div>
            </div>
            <span className='project-type'>{project.type}</span>
            <h2 className='project-name'>{project.name}</h2>
            <p className='project-description'>{project.description}</p>

            <div className='two-sec'>
              <p className='project-deadline'>
                <span><FaStopwatch /> {moment(project.deadline).format('DD-MM-YYYY')}</span>
              </p>
              {/* <p className='project-developer'>
                <span id="dev">{project.developer}</span>
              </p> */}
            </div>
            </a>
          </div>
        
          
          </>
        
  

        ))}
        </>
}
        {viewType == 'list' &&
        <ProjectsTable projects={projects} />
      }
        <div className='projects-div start-new' onClick={handleToggleForm}>
          <TiPlusOutline />
          Start New Project or <a href="">schedule call</a>
        </div>
      </div>
      <NewProject
        show={showOnboardForm}
        onHide={handleToggleForm}
        clients={clients}
        onAddProject={handleAddProject}
      />
    </Container>
  );
};

export default ClientProjects;
