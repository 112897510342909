import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";

import AnimatedNumbers from "react-animated-numbers";


function Who() {
    const [num, setNum] = useState(40);
    const [client, setClient] = useState(200);
    const [growth, setGrowth] = useState(300);
    const [exp, seteXP] = useState(8);

  return (
    <Container>


      <div className="who">
      
   
       </div>
       <div className="numbers">
         <div className="number">
            <h2>
            <AnimatedNumbers
        includeComma
        className="change"
        transitions={(index) => ({
          type: "spring",
          duration: index + 2,
        })}
        animateToNumber={num} 
        fontStyle={{
          fontSize: 40,
          color: "white",
         
        }}
      /> 
            </h2>
            <p>Active companies</p>
         </div>
         <div className="number">
            <h2>
            <AnimatedNumbers
        includeComma
        className="change"
        transitions={(index) => ({
          type: "spring",
          duration: index + 2,
        })}
        animateToNumber={client} 
        fontStyle={{
          fontSize: 40,
          color: "white",
         
        }}
      /> 
            </h2>
            <p>Projects Delivered</p>
         </div> <div className="number">
            <h2>
            <AnimatedNumbers
        includeComma
        className="growth"
        transitions={(index) => ({
          type: "spring",
          duration: index + 2,
        })}
        animateToNumber={growth} 
        fontStyle={{
          fontSize: 40,
          color: "white",
         
        }}
      /> 
            </h2>
            <p>Growth Probablity</p>
         </div> <div className="number">
            <h2>
            <AnimatedNumbers
        includeComma
        className="change"
        transitions={(index) => ({
          type: "spring",
          duration: index + 2,
        })}
        animateToNumber={exp} 
        fontStyle={{
          fontSize: 40,
          color: "white",
         
        }}
      /> 
            </h2>
            <p>Total Experience</p>
         </div>
       </div>

    </Container>
  );
}

export default Who;
