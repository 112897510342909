import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Slider from "../../components/Slider";
function NotFound() {

  return (
    <Container>
       <div className="notfound">
         <h1>404</h1>
         <p>Page Not Found</p>
         <div className="buttons">
          <button>Go Back</button>
          <button>Contact us</button>
         </div>
       </div>
    </Container>
  );
}

export default NotFound;
