import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

const NewProject = ({ show, onHide, onAddProject }) => {
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedDev, setSelectedDev] = useState('');
  const [clientList, setClientList] = useState([]);
  const [users, setUsers] = useState([]);
  const userToken = localStorage.getItem('userToken');
  const [loading, setLoading] = useState(false);

  const [projectData, setProjectData] = useState({
    name: '',
    owner: '',
    phone: '',
    clientId: '',
    projectManagerId: '',
    designFileLink: '',
    communicationLink: '',
    totalTasks: 0,
    assigneeId: '',
    completedTasks: 0,
    totalMeetings: 0,
    totalTimeSpent: '',
    totalDevelopers: 0,
    tag: '',
    priority: 'High',
    budget: '',
    totalPhase: 0,
    nextPhaseMeeting: '',
    completedPhase: 0,
    paid: '',
    due: '',
    documents: [],
    deadline: '',
    server: '',
    demoLink: '',
    productionLink: '',
    nextPaymentDate: '',
    reviews: [],
    description: ''
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/all-users`, {
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/all-users`, {
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    })
      .then((response) => {
        setClientList(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching clients:', error);
      });
  }, [userToken]);

  const handleClientSelect = (clientId) => {
    const selectedClient = clientList.find(client => client.clientIdMongo === clientId);
    setSelectedClient(selectedClient);
    setProjectData({
      ...projectData,
      clientId: clientId,
      owner: selectedClient.fullName,

    });
  };
  const handleManagerSelect = (clientId) => {
    const selectedClient = clientList.find(client => client.clientIdMongo === clientId);
    setSelectedManager(selectedClient);
    setProjectData({
      ...projectData,
      projectManagerId: clientId,
      manager:selectedManager.fullName
    });
  };

  const handleDevSelect = (clientId) => {
    const selectedClient = clientList.find(client => client.clientIdMongo === clientId);
    setSelectedDev(selectedClient);
    setProjectData({
      ...projectData,
      assigneeId: clientId,
      developer:selectedDev.fullName,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const newProjectData = {
      ...projectData,
      ownerId: projectData.projectManagerId,
      projectManagerId: projectData.projectManagerId,
      assigneeId: projectData.projectManagerId,
    };

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/v1/projects`, newProjectData, {
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    })
      .then((response) => {
        message.success('Project created successfully');
        onAddProject(response.data.data); // Add new project to the list
        setLoading(false);
        onHide();
      })
      .catch((error) => {
        console.error('Error creating project:', error);
        message.error('Failed to create project');
        setLoading(false);
      });
  };


  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Start A New Project 👋</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Project Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={projectData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Project Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={projectData.description}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="designFileLink">
            <Form.Label>Design File Link (Figma or Adobe)</Form.Label>
            <Form.Control
              type="text"
              name="designFileLink"
              value={projectData.designFileLink}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="priority">
            <Form.Label>Priority</Form.Label>
            <Form.Control
              as="select"
              name="priority"
              value={projectData.priority}
              onChange={handleChange}
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="type">
            <Form.Label>Application Type</Form.Label>
            <Form.Control
              as="select"
              name="type"
              value={projectData.type}
              onChange={handleChange}
              required
            >
               <option value="">Select</option>
              <option >Web Application</option>
              <option>Mobile Application</option>
              <option >Desktop Application</option>
              <option >SaaS Application</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="client">
            <Form.Label>Select Client</Form.Label>
            <Form.Control
              as="select"
              name="client"
              value={selectedClient ? selectedClient.clientIdMongo : ''}
              onChange={(e) => handleClientSelect(e.target.value)}
              required
            >
              <option value="">Select Client</option>
              {clientList.map(client => (
                <option key={client.clientIdMongo} value={client.clientIdMongo}>{client.fullName}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="manager">
            <Form.Label>Project Manager</Form.Label>
            <Form.Control
              as="select"
              name="manager"
              value={selectedManager ? selectedManager.clientIdMongo : ''}
              onChange={(e) => handleManagerSelect(e.target.value)}
              required
            >
              <option value="">Select Client</option>
              {clientList.map(manager => (
                <option key={manager.clientIdMongo} value={manager.clientIdMongo}>{manager.fullName}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="developer">
            <Form.Label>Project Developer</Form.Label>
            <Form.Control
              as="select"
              name="developer"
              value={selectedDev ? selectedDev.clientIdMongo : ''}
              onChange={(e) => handleDevSelect(e.target.value)}
              required
            >
              <option value="">Select Client</option>
              {clientList.map(developer => (
                <option key={developer.clientIdMongo} value={developer.clientIdMongo}>{developer.fullName}</option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="budget">
            <Form.Label>Budget</Form.Label>
            <Form.Control
              type="number"
              name="budget"
              value={projectData.budget}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group controlId="paid">
            <Form.Label>Paid</Form.Label>
            <Form.Control
              type="number"
              name="paid"
              value={projectData.paid}
              onChange={handleChange}
            />
  </Form.Group> */}
  {/* <Form.Group controlId="nextPaymentDate">
        <Form.Label>Next Payment Date</Form.Label>
        <Form.Control
          type="date"
          name="nextPaymentDate"
          value={projectData.nextPaymentDate}
          onChange={handleChange}
        />
      </Form.Group>  */}
  <Form.Group controlId="deadline">
            <Form.Label>Project Deadline</Form.Label>
            <Form.Control
              type="date"
              name="deadline"
              value={projectData.deadline}
              onChange={handleChange}
            />
          </Form.Group>

        
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Creating...' : 'Save'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NewProject;
