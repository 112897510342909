import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';
import { Container } from 'react-bootstrap';

const CustomTreeNode = ({ name, role, imgSrc }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img src={imgSrc} alt={name} style={{ width: '50px', height: '50px', borderRadius: '20%', marginRight: '8px' }} />
    <div>
      <div style={{ fontSize: '18px', color: 'whiet' }}>{name}</div>
      <div style={{ fontSize: '14px', color: 'gray' }}>{role}</div>
    </div>
  </div>
);

const teamData = [
  {
    name: "Aman Sharma",
    role: "Founder",
    imgSrc: "https://api.multigrad.in/open/media/?file=1695584577430.png",
    children: [
      {
        name: "Sandeep Shakya",
        role: "Marketing",
        imgSrc: "/images/sandeep.jpg",
      },
      {
        name: "Abhishek Yadav",
        role: "Product",
        imgSrc: "/images/abhishek.jpg",
        children: [
          {
            name: "Kshitiz Dayal",
            role: "Developer 1",
            imgSrc: "/images/kshitiz.jpg",
            children: [
              {
                name: "Developer 9",
                role: "Developer",
                imgSrc: "/images/developer9.jpg",
              },
            ],
          },
          {
            name: "Vishwajeet",
            role: "Developer 2",
            imgSrc: "/images/vishwajeet.jpg",
          },
          {
            name: "Developer 3",
            role: "Developer",
            imgSrc: "/images/developer3.jpg",
          },
        ],
      },
    ],
  },
];

// Convert JSON data to tree data format
const convertToTreeData = (data) => {
  return data.map(item => ({
    title: <CustomTreeNode name={item.name} role={item.role} imgSrc={item.imgSrc} />,
    key: item.name,
    children: item.children ? convertToTreeData(item.children) : null,
  }));
};

const TeamTree = () => {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
   
    setTreeData(convertToTreeData(teamData));
  }, []);

  return (
    <Container>
      <Tree
        showLine
        defaultExpandAll
        treeData={treeData}
      />
    </Container>
  );
};

export default TeamTree;
