import React from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import { FaStopwatch } from "react-icons/fa";

const ProjectsTable = ({ projects }) => {
  return (
    <Table striped bordered hover className='clients-table'>
      <thead>
        <tr>
         
          <th>Type</th>
          <th>Name</th>
          <th>Deadline</th>
          <th>Developer</th>
          <th>Progress</th>
          <th>Priority</th>
          <th>Manage</th>
        </tr>
      </thead>
      <tbody>
        {projects.map(project => (
          <tr key={project.id}>
          
            <td>{project.type}</td>
            <td>{project.name}</td>
           
            <td>{project.deadline}</td>
            <td>{project.developer}</td>
            <td>{project.progress}%</td>
            <td>{project.priority}</td>
            <td className='action-btn'>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={`#/projects/${project.id}`}>View Project</Dropdown.Item>
                  <Dropdown.Item href={`#/projects/${project.id}/tasks`}>View Tasks</Dropdown.Item>
                  <Dropdown.Item href={`#/projects/${project.id}/edit`}>Edit Project</Dropdown.Item>
                  <Dropdown.Item href={`#/projects/${project.id}/delete`}>Delete Project</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProjectsTable;
