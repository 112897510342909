import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import LoginComp from "../../components/LoginComp/LoginComp";
function Login() {

  return (
    <Container>
          <LoginComp />
     
    </Container>
  );
}

export default Login;
