import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Brand from "../../assets/media/ill.avif";
import Inventory from "./Inventory";

function SliderMark() {

  return (
    <Container>
   
   <div className="who">
  <div className="rl-child">
    <h4>~Marketplace</h4>
    <h2>All the Software You Need to Run Your Business</h2>
    <p>Explore our comprehensive marketplace, where you can find a wide range of software solutions. Whether you're looking to streamline your operations, enhance productivity, or drive growth, our curated selection of top-tier software products has you covered. Empower your business with the tools designed to simplify workflows, improve efficiency, and foster innovation.</p>
    <a href="https://tally.so/r/mOP80g"><button id="btn">+ Sell Your Product </button></a>
  </div>
  <div className="rl-child">
    <div className="transnew">
      <img src={Brand} alt="Brand Logo"/>
    </div>
  </div>
</div>


     

<Inventory />


 


        <div className="meet-team" id="communty">
        <h2 id="center">Let us find right fit for you?</h2>
         <div className="connect meet">
           <a href="https://calendly.com/rapydlaunch/30min" target="_blank">Let's Talk</a>
         </div>
        </div>






    </Container>
  );
}

export default SliderMark;
